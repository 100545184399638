import React from 'react';
import './All.css';
import dream from "./../dream.PNG";
import dream1 from "./../dream1.PNG";
import dream2 from "./../dream2.PNG";


function Dream() {


  return (
    <section>
      <div id="all-items">
        <h1>DREAMSCAPE</h1>
        <p>
        Our project aims to create a website focused on improving users' sleep experiences.
        We provide exercises tailored for individuals struggling with sleep issues like insomnia.
        We encourage users to sign up for access to additional features and personalized support.
        Our platform offers entertaining features to engage users and make their experience enjoyable.
        Additionally, we provide services enabling users to record and track their sleep data and dreams.
        <br></br> <br></br>
        click <a href=" https://dreamscp.netlify.app/">here</a> to see the web app.
        <br></br>
        Visit the source <a href="https://gitlab.com/csy3dawson23-24/620/section-1/GroupFour-FarhanHoomanAshleySila/dreamscape">source code</a>.
        </p>
          <table>
            <tr>
              <td><img className="all-images" src={dream} alt="dream"></img></td>
              <td><img className="all-images" src={dream1} alt="dream1"></img></td>
              <td><img className="all-images" src={dream2} alt="dream2"></img></td>
            </tr>
          </table>
        <div id="down">
          <div id="all-left">
          <h3>Languages & Frameworks</h3>
          <ul>
              <li>React.js(frontend)</li>
              <li>Node.js(backend) </li>
              <li>MongoDb-database</li>
              <li>GitLab CI - CI/CD</li>
              <li>HTML,CSS</li>
            </ul>
          </div>
          <div id="all-right">
          <h3>Features</h3>
          <ul>
              <li>Signup/login using Google token - passwords are hashed</li>
              <li>Chart bar displaying number of hours slept per day</li>
              <li>Dream journal, SPD tracking map, etc</li>
              <li>other excersises to improve sleep such as breathing cycle </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Dream;